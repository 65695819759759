import React, { Component } from 'react'
import { injectStripe } from 'react-stripe-elements'
import MemberDetails from './MemberDetails'
import PaymentDetails from '../../components/PaymentDetails'
import config from "../../config"
import * as request from 'request-promise-native'
import Confirmation from '../../components/Confirmation'

class MemberForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mainPlans: [{
        id: 0,
        name: '',
        product: '',
        cost: 0,
      }],
      spousePlans: [{
        id: 0,
        name: '',
        product: '',
        cost: 0,
      }],
      memberDetails: {},
      paymentDetails: {},
      step: 0,
      error: ''
    }

    this.form = React.createRef()

    const fiscalYearStartMonth = config.fiscalYearStart
    const today = new Date()
    this.membershipYear = today.getMonth() >= fiscalYearStartMonth ? today.getFullYear() + 1 : today.getFullYear()
  }

  costDesc = (a, b) => {
    let comparison = 0
    if (a.cost > b.cost) {
      comparison = -1
    } else if (a.cost < b.cost) {
      comparison = 1
    }
    return comparison
  }

  componentDidMount() {
    const options = {
      method: 'GET',
      uri: `${config.baseUrl}/product`,
      json: true
    }

    request(options).then(req => {
      const { res, err } = req
      if (!err) {
        const main = res.filter(product => {
          const { metadata } = product
          if ('type' in metadata) {
            return metadata.type.toLowerCase() !== 'shm'
          } else {
            return true
          }
        })
        const spouse = res.filter(product => {
          const { metadata } = product
          return metadata.type ? metadata.type === 'shm' : false
        })
        let mainPlans = []
        let spousePlans = []
        main.forEach(m => {
          if ('plans' in m) {
            m.plans.forEach(p => {
              p.product = m.name
              p.notice = m.metadata.notice ? m.metadata.notice : null
              mainPlans.push(p)
            })
          }
        })
        spouse.forEach(m => {
          if ('plans' in m) {
            m.plans.forEach(p => {
              p.product = m.name
              p.notice = m.metadata.notice ? m.metadata.notice : null
              spousePlans.push(p)
            })
          }
        })
        mainPlans.sort(this.costDesc)
        spousePlans.sort(this.costDesc)
        this.setState({
          mainPlans,
          spousePlans
        })
        this.form.current.style.visibility = 'visible'
        this.form.current.style.opacity = 1;
      }
    })
  }

  handleSubmit = async (target) => {
    const { token, cardholder, details } = target
    const { companyAddress } = details

    const body = {
      fiscalYearStart: config.fiscalYearStart,
      plan: details.plan,
      addSpouse: details.addSpouse,
      spousePlan: details.spousePlan,
      customer: {
        name: details.name,
        email: details.email,
        phone: details.cellPhone,
        address: cardholder.address_line1,
        city: cardholder.address_city,
        state: cardholder.address_state,
        zip: cardholder.address_zip,
        country: details.address_country,
        metadata: {
          'Company Name': details.companyName,
          'Company Title': details.companyTitle,
          'Company Address': companyAddress.address,
          'Company City': companyAddress.city,
          'Company State': companyAddress.state,
          'Company Zip': companyAddress.zip,
          'Company Country': companyAddress.country,
          'Second Household Member Name': details.spouseName,
          'Attend Dinners': details.attendDinners,
          'Second Household Member Attend Dinners': details.spouseAttendDinners,
          'Send Mail To': details.sendMailTo,
          'Home Address': details.address,
          'Home City': details.city,
          'Home State': details.state,
          'Home Zip': details.zip,
          'Home Country': details.country,
          'Home Phone': details.homePhone,
          'Cell Phone': details.cellPhone,
          'Work Phone': details.workPhone,
        }
      },
      payment: {
        token: token.id,
        cardholder
      },
    }

    const options = {
      method: 'POST',
      uri: `${config.baseUrl}/membership`,
      body,
      json: true
    }

    const req = await request(options)

    if (req.err) {
      if (typeof req.err === 'string') {
        this.setState({
          error: req.err
        })
      } else if ('raw' in req.err && 'message' in req.err.raw) {
        this.setState({
          error: req.err.raw.message
        })
      } else {
        this.setState({
          error: 'Something went wrong when processing your payment.'
        })
      }
    } else {
      this.setState({
        error: '',
        step: 2
      })
    }
  }

  render() {
    const { membershipYear } = this
    const { step } = this.state

    return (
      <div ref={this.form} className={'fade-in'}>
        { step === 0 ?
          <MemberDetails
            onSubmit={values => {
              this.setState({
                step: 1,
                memberDetails: values
              })
            }}
            mainPlans={this.state.mainPlans}
            spousePlans={this.state.spousePlans}
            memberDetails={this.state.memberDetails}
            membershipYear={membershipYear}
          />
          : step === 1 ?
            <PaymentDetails
              notices={[
                'By clicking pay, you authorize Bluecoats, Inc. to charge your card.',
                'You must contact Bluecoats, Inc. to make changes to or cancel your membership. Please visit the website for the latest contact information.',
                `Please note that your Bluecoats membership is for the duration of the year ${membershipYear}.`
              ]}
              tokenName={'Membership'}
              details={this.state.memberDetails}
              onBack={() => {
                this.setState({
                  step: 0,
                })
              }}
              error={this.state.error}
              onSubmit={this.handleSubmit}
            />
            : step === 2 ?
            <Confirmation email={this.state.memberDetails.email} dues={true}/>
            : null
        }
      </div>
    )
  }
}

export default injectStripe(MemberForm)
