import React, { Component } from 'react'
import { Elements } from 'react-stripe-elements'
import DonateForm from '../components/DonateForm'

class Donate extends Component {
  render() {
    return (
      <div>
        <Elements>
          <DonateForm/>
        </Elements>
      </div>
    )
  }
}

export default Donate