import React, { Component } from 'react'
import { injectStripe } from 'react-stripe-elements'
import DonateDetails from './DonateDetails'
import PaymentDetails from '../../components/PaymentDetails'
import config from '../../config'
import * as request from 'request-promise-native'
import Confirmation from '../../components/Confirmation'

class DonateForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      donateDetails: {},
      paymentDetails: {},
      step: 0,
      error: ''
    }
  }

  handleSubmit = async (target) => {
    const { token, cardholder, details } = target

    const body = {
      payment: {
        amount: details.amount,
        token: token.id,
        metadata: {
          message: details.message,
        },
        cardholder
      },
      customer: {
        name: details.name,
        email: details.email,
        phone: details.phone,
        address: details.address,
        city: details.city,
        state: details.state,
        zip: details.zip,
        country: details.country,
      }
    }

    const options = {
      method: 'POST',
      uri: `${config.baseUrl}/donate`,
      body,
      json: true
    }

    const req = await request(options)

    if (req.err) {
      if (typeof req.err === 'string') {
        this.setState({
          error: req.err
        })
      } else if ('raw' in req.err && 'message' in req.err.raw) {
        this.setState({
          error: req.err.raw.message
        })
      } else {
        this.setState({
          error: 'Something went wrong when processing your payment.'
        })
      }
    } else {
      this.setState({
        error: '',
        step: 2
      })
    }
  }

  render() {
    const { step } = this.state

    return (
      <div>
        { step === 0 ?
          <DonateDetails
            onSubmit={values => {
              this.setState({
                step: 1,
                donateDetails: values
              })
            }}
            donateDetails={this.state.donateDetails}
          />
          : step === 1 ?
          <PaymentDetails
            notices={[
              'By clicking pay, you authorize Bluecoats, Inc. to charge your card.',
            ]}
            tokenName={'Donation'}
            details={this.state.donateDetails}
            onBack={() => {
              this.setState({
                step: 0,
              })
            }}
            error={this.state.error}
            onSubmit={this.handleSubmit}
            paymentRequestType={'donate'}
          />
          : step === 2 ?
          <Confirmation email={this.state.donateDetails.email}/>
          : null
        }
      </div>
    )
  }
}

export default injectStripe(DonateForm)