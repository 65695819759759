import React, { Component } from 'react'
import CurrencyInput from "react-currency-input"
import PhoneInput from 'react-phone-number-input/basic-input'
import AddressBlock from '../../components/AddressBlock'

class DonateDetails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      phone: props.donateDetails.phone ? props.donateDetails.phone : '',
      amount: props.donateDetails.amount ? props.donateDetails.amount : 0,
      showState: props.donateDetails.country === 'US',
      phoneChanged: false,
      amountChanged: false,
      countryChanged: false,
      initial: true,
    }

    this.phoneField = React.createRef()
    this.handlePhoneChange = this.handlePhoneChange.bind(this)
    this.handleAmountChange = this.handleAmountChange.bind(this)
  }

  handlePhoneChange(value) {
    this.setState({
      phone: value,
      phoneChanged: true,
      amountChanged: false,
      initial: false
    })
  }

  handleAmountChange(event, value) {
    this.setState({
      amount: value,
      initial: false,
      amountChanged: true,
      phoneChanged: false,
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.state.initial) {
      if (this.state.phoneChanged) {
        this.phoneField.current.focus()
      }
    }
  }

  handleSubmit = ev => {
    const { onSubmit } = this.props
    const { target } = ev

    ev.preventDefault()

    onSubmit({
      amount: this.state.amount,
      name: target.name.value,
      email: target.email.value,
      phone: this.state.phone,
      address: target.address.value,
      city: target.city.value,
      state: target.state ? target.state.value : '',
      zip: target.zip.value,
      country: target.country.value,
      message: target.message.value
    })
  }

  isSelected = countryCode => {
    const { donateDetails } = this.props
    let defaultCountry = 'US';
    if (donateDetails.country) {
      defaultCountry = donateDetails.country
    }

    if (defaultCountry === countryCode) {
      return 'selected'
    } else {
      return ''
    }
  }

  render() {
    const { donateDetails } = this.props

    return (
      <form id={'payment-form'} onSubmit={this.handleSubmit}>
        <p className="instruction"><span>Enter your one time donation details below</span></p>
        <section>
          <fieldset>
            <label>
              <span>Amount</span>
                <CurrencyInput
                  inputMode={'numeric'}
                  value={this.state.amount}
                  onChange={this.handleAmountChange}
                  name={'amount'} className={'field'} prefix={'$'} required/>
            </label>
          </fieldset>
        </section>
        <section>
          <h2>Contact Information</h2>
          <fieldset className="with-state">
            <label>
              <span>Name</span>
              <input defaultValue={donateDetails.name ? donateDetails.name : ''}
                name="name" className="field" placeholder="Jenny Rosen" required/>
            </label>
            <label>
              <span>Email</span>
              <input defaultValue={donateDetails.email ? donateDetails.email : ''}
                name="email" type="email" className="field" placeholder="jenny@example.com" required/>
            </label>
            <label>
              <span>Phone</span>
              <PhoneInput
                ref={this.phoneField}
                name={'phone'}
                className={'field'}
                placeholder={'(555) 555-5555'}
                country={'US'}
                value={this.state.phone}
                onChange={this.handlePhoneChange}
                required
              />
            </label>
            <AddressBlock defaultValue={donateDetails}/>
          </fieldset>
          <h2>Optional</h2>
          <fieldset>
            <label style={{ height: 'auto', display: 'block'}}>
              <span style={{display: 'block', float: 'left'}}>Message</span>
              <textarea
                style={{resize: 'vertical', width: 'calc(100% - 140px)', border: 'none'}}
                className={'field'}
                name={'message'}
              />
            </label>
          </fieldset>
        </section>
        <button disabled={!this.state.amount} className="payment-button" type="submit">Next</button>
      </form>
    )
  }
}

export default DonateDetails