import React, { Component } from 'react'
import { Elements } from 'react-stripe-elements'
import MemberForm from '../components/MemberForm'

class Member extends Component {
  render() {
    return (
      <div>
        <Elements>
          <MemberForm/>
        </Elements>
      </div>
    )
  }
}

export default Member