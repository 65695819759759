import React, { Component } from 'react'

class Confirmation extends Component {
  render() {
    const { email, dues } = this.props

    let label = `Thank you for your payment. A receipt has been sent to ${email}`;
    if (dues) {
      if (new Date().getMonth() >= 11) {
        label = `Thank you for your payment. Your payment has been scheduled for Jan 1 ${new Date().getFullYear() + 1} and a receipt will be sent to ${email}`
      } else {
        label = `Thank you for your payment. Your payment has been scheduled and a receipt will be sent to ${email}`
      }
    }

    return (
      <div id={'payment-form'}>
        <h1>Confirmation</h1>
        <label>{label}</label>
      </div>
    )
  }
}

export default Confirmation