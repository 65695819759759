import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

class Home extends Component {
  render() {
    const { history } = this.props

    return (
      <div id={'payment-form'}>
        <h1>Bluecoats Payments Home</h1>
        <label>Please select one of the options below for making a payment to Bluecoats, Inc.</label>
        <div style={{marginTop: 20}}>
          <button className={'home-button'} style={{marginRight: 25}} onClick={() => history.push('/donate')}>
            One Time Donation
          </button>
          <button className={'home-button'} onClick={() => history.push('/dues')}>
            Pay Dues
          </button>
        </div>
      </div>
    )
  }
}

export default withRouter(Home)