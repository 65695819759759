import React, { Component } from 'react'
import AddressBlock from '../../components/AddressBlock'
import PhoneInput from "react-phone-number-input/basic-input"

class MemberDetails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      addSpouse: 'addSpouse' in props.memberDetails ? props.memberDetails.addSpouse : false,
      attendDinners: 'attendDinners' in props.memberDetails ? props.memberDetails.attendDinners : true,
      spouseAttendDinners: 'spouseAttendDinners' in props.memberDetails ? props.spouseAttendDinners : true,
      cellPhone: props.memberDetails.cellPhone ? props.memberDetails.cellPhone : '',
      homePhone: props.memberDetails.homePhone ? props.memberDetails.homePhone : '',
      workPhone: props.memberDetails.workPhone ? props.memberDetails.workPhone : '',
      cellChanged: false,
      homeChanged: false,
      workChanged: false,
      initial: true,
      selectedPlan: props.memberDetails.plan ? props.mainPlans.findIndex(p => p.id === props.memberDetails.plan) : 0,
      selectedSpousePlan: props.memberDetails.spousePlan ? props.spousePlans.findIndex(p => p.id === props.memberDetails.spousePlan) : 0,
      errorMessage: null
    }

    this.cellPhone = React.createRef()
    this.workPhone = React.createRef()
    this.homePhone = React.createRef()
    this.handleCellPhoneChange = this.handleCellPhoneChange.bind(this)
    this.handleHomePhoneChange = this.handleHomePhoneChange.bind(this)
    this.handleWorkPhoneChange = this.handleWorkPhoneChange.bind(this)
  }

  handleCellPhoneChange(value) {
    this.setState({
      cellPhone: value,
      cellChanged: true,
      homeChanged: false,
      workChanged: false,
      initial: false
    })
  }

  handleWorkPhoneChange(value) {
    this.setState({
      workPhone: value,
      cellChanged: false,
      homeChanged: false,
      workChanged: true,
      initial: false
    })
  }

  handleHomePhoneChange(value) {
    this.setState({
      homePhone: value,
      cellChanged: false,
      homeChanged: true,
      workChanged: false,
      initial: false,
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.state.initial) {
      if (this.state.cellChanged) {
        this.cellPhone.current.focus()
      } else if (this.state.homeChanged) {
        this.homePhone.current.focus()
      } else if (this.state.workChanged) {
        this.workPhone.current.focus()
      }
    }
  }

  getAmount = () => {
    const { spousePlans, mainPlans } = this.props
    const { selectedPlan: _sp, selectedSpousePlan: _ssp } = this.state

    const selectedPlan = mainPlans[_sp]
    const selectedSpousePlan = spousePlans[_ssp]

    let amount = selectedPlan.cost
    if (this.state.addSpouse) {
      amount += selectedSpousePlan.cost
    }
    return amount
  }

  handleSubmit = ev => {
    const { onSubmit } = this.props
    const { target } = ev

    ev.preventDefault()

    const emailValidation = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i

    if (!emailValidation.test(target.email.value)) {
      this.setState({
        errorMessage: 'Email is not in a valid format.'
      })
    } else {
      onSubmit({
        amount: this.getAmount(),
        plan: target.plan.value,
        name: target.name.value,
        email: target.email.value,
        cellPhone: target.cellPhone.value,
        homePhone: target.homePhone.value,
        workPhone: target.workPhone.value,
        address: target.address.value,
        city: target.city.value,
        state: target.state.value,
        zip: target.zip.value,
        country: 'US',
        addSpouse: this.state.addSpouse,
        attendDinners: this.state.attendDinners,
        spouseAttendDinners: this.state.spouseAttendDinners,
        spousePlan: target.spousePlan ? target.spousePlan.value : '',
        spouseName: target.spouseName ? target.spouseName.value : '',
        companyName: target.companyName.value,
        companyTitle: target.companyTitle.value,
        companyAddress: {
          address: target.company_address.value,
          city: target.company_city.value,
          state: target.company_state.value,
          zip: target.company_zip.value,
          country: 'US',
        },
        sendMailTo: target.sendMailTo.value,
      })
    }
  }

  render() {
    const { memberDetails, spousePlans, mainPlans, membershipYear } = this.props
    const { selectedPlan: _sp, selectedSpousePlan: _ssp, errorMessage } = this.state

    const selectedPlan = mainPlans[_sp]
    const selectedSpousePlan = spousePlans[_ssp]

    return (
      <form id={'payment-form'} onSubmit={this.handleSubmit}>
        <p className={'instruction'}><span>Enter your membership details below for year {membershipYear}</span></p>
        <section>
          <h2>Your Information</h2>
          <fieldset className={'with-state'}>
            <label className={'select'}>
              <span>Classification</span>
              <div className={'field'}>
                <select
                  defaultValue={memberDetails.plan ? memberDetails.plan : selectedPlan.id }
                  name={'plan'}
                  style={{width: '100%'}}
                  onChange={o => {
                    this.setState({
                      selectedPlan: mainPlans.findIndex(p => p.id === o.target.value)
                    })
                  }}
                >
                  {
                    mainPlans.map((plan, key) => {
                      return <option key={key} value={plan.id}>{plan.name} ${plan.cost}</option>
                    })
                  }
                </select>
              </div>
            </label>
            <label>
              <span>Name</span>
              <input defaultValue={memberDetails.name ? memberDetails.name : ''}
                     name="name" className="field" placeholder="Jenny Rosen" required/>
            </label>
            <label>
              <span>Email</span>
              <input defaultValue={memberDetails.email ? memberDetails.email : ''}
                     name="email" type="email" className="field" placeholder="jenny@example.com" required/>
            </label>
            <label>
              <span>Cell Phone</span>
              <PhoneInput
                ref={this.cellPhone}
                name={'cellPhone'}
                className={'field'}
                placeholder={'(555) 555-5555'}
                country={'US'}
                value={this.state.cellPhone}
                onChange={this.handleCellPhoneChange}
                required
              />
            </label>
            <label>
              <span>Home Phone</span>
              <PhoneInput
                ref={this.homePhone}
                name={'homePhone'}
                className={'field'}
                placeholder={'If different than cell phone'}
                country={'US'}
                value={this.state.homePhone}
                onChange={this.handleHomePhoneChange}
              />
            </label>
            <AddressBlock defaultValue={memberDetails} hideCountry={true} prefix={'Home'}/>
          </fieldset>
          <label style={{lineHeight: .8, fontSize: '14px'}}>
            I will not attend dinners
            <input
              onChange={() => this.setState({attendDinners: !this.state.attendDinners})}
              checked={!this.state.attendDinners}
              type={'checkbox'} style={{marginBottom: 15, float: 'left', marginRight: 10}}/>
          </label>
          <label style={{lineHeight: .8, fontSize: '14px'}}>
            Add second household member
            <input
              onChange={() => this.setState({addSpouse: !this.state.addSpouse})}
              defaultChecked={this.state.addSpouse}
              type={'checkbox'} style={{marginBottom: 15, float: 'left', marginRight: 10}}/>
          </label>
        </section>
        { this.state.addSpouse ?
          <section>
            <h2>Second Household Member Information</h2>
            <fieldset>
              <label className={'select'}>
                <span>Classification</span>
                <div className={'field'}>
                  <select
                    defaultValue={memberDetails.spousePlan ? memberDetails.spousePlan : selectedSpousePlan.id}
                    onChange={o => {
                      this.setState({
                        selectedSpousePlan: spousePlans.findIndex(p => p.id === o.target.value)
                      })
                    }}
                    name={'spousePlan'}
                    style={{width: '100%'}}
                  >
                    {
                      spousePlans.map((plan, key) => {
                        return <option key={key} value={plan.id}>{plan.name} ${plan.cost}</option>
                      })
                    }
                  </select>
                </div>
              </label>
              <label>
                <span>Name</span>
                <input defaultValue={memberDetails.spouseName ? memberDetails.spouseName : ''}
                       name="spouseName" className="field" placeholder="Joe Rosen" required/>
              </label>
            </fieldset>
            <label style={{lineHeight: .8, fontSize: '14px'}}>
              Second household member will not attend dinners
              <input
                onChange={() => this.setState({spouseAttendDinners: !this.state.spouseAttendDinners})}
                defaultChecked={!this.state.spouseAttendDinners}
                type={'checkbox'} style={{marginBottom: 15, float: 'left', marginRight: 10}}/>
            </label>
          </section>
        : null }
        <section>
          <h2>Company Information</h2>
          <fieldset className={'with-state'}>
            <label>
              <span>Name</span>
              <input defaultValue={memberDetails.companyName ? memberDetails.companyName : ''}
                     name={'companyName'} className={'field'} placeholder={'Bluecoats, Inc.'} required/>
            </label>
            <AddressBlock
              name={'company'}
              hideCountry={true}
              defaultValue={memberDetails.companyAddress}
              prefix={'Work'}
            />
            <label>
              <span>Your Title</span>
              <input defaultValue={memberDetails.companyTitle ? memberDetails.companyTitle : ''}
                     name={'companyTitle'} className={'field'} placeholder={'CEO'} required/>
            </label>
            <label>
              <span>Work Phone</span>
              <PhoneInput
                ref={this.workPhone}
                name={'workPhone'}
                className={'field'}
                placeholder={'(555) 555-5555'}
                country={'US'}
                value={this.state.workPhone}
                onChange={this.handleWorkPhoneChange}
                required
              />
            </label>
          </fieldset>
        </section>
        <section>
          <h2>Send Bluecoats Mail To</h2>
          <fieldset>
            <label className={'select'}>
              <span>Address</span>
              <div className={'field'}>
                <select
                  defaultValue={memberDetails.sendMailTo ? memberDetails.sendMailTo : 'business'}
                  name={'sendMailTo'}
                  style={{width: '100%'}}
                >
                  <option value={'business'}>Business</option>
                  <option value={'home'}>Home</option>
                </select>
              </div>
            </label>
          </fieldset>
        </section>
        <section>
          <h2>Amount</h2>
          <label style={{fontSize: 14, marginBottom: 15}}>
            {`${membershipYear} ${selectedPlan.product} - ${selectedPlan.name}: $${selectedPlan.cost}`}
          </label>
          {this.state.addSpouse ?
            <label style={{fontSize: 14, marginBottom: 15}}>
              {`${membershipYear} ${selectedSpousePlan.product} - ${selectedSpousePlan.name}: $${selectedSpousePlan.cost}`}
            </label>
          : null }
          <fieldset style={{marginTop: 5}}>
            <label>
              <span>Total</span>
              <div className={'field'}>
                {this.state.addSpouse ?
                  `$${selectedPlan.cost + selectedSpousePlan.cost}`
                  :
                  `$${selectedPlan.cost}`
                }
              </div>
            </label>
          </fieldset>
          {selectedPlan.notice ?
            <p className={'tip'}>{selectedPlan.notice}</p>
            : null
          }
        </section>
        {errorMessage && errorMessage !== '' ? <label style={{display: 'block', fontSize: 14, color: 'red', marginBottom: 25}}>{errorMessage}</label> : null }
        <button className="payment-button" type="submit">Next</button>
      </form>
    )
  }
}

MemberDetails.defaultProps = {
  mainPlans: [{
    id: 0,
    cost: 0,
    name: '',
    product: ''
  }],
  spousePlans: [{
    id: 0,
    cost: 0,
    name: '',
    product: ''
  }]
}

export default MemberDetails
