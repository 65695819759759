import React, { Component } from 'react'
import CountrySelect from "./CountrySelect"

class AddressBlock extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showState: props.defaultValue.country ? props.defaultValue.country === 'US' : true,
    }
  }

  render() {
    const { defaultValue, name: _name, hideCountry, prefix } = this.props
    let name = _name
    if (name !== '') {
      name += '_'
    }
    return (
      <React.Fragment>
        <label>
          <span>{prefix ? `${prefix} Address` : 'Address'}</span>
          <input defaultValue={defaultValue.address ? defaultValue.address : ''}
                 name={name + "address"} className="field" placeholder="185 Berry Street Suite 550" required/>
        </label>
        <label>
          <span>City</span>
          <input defaultValue={defaultValue.city ? defaultValue.city : ''}
                 name={name + "city"} className="field" placeholder="San Francisco" required/>
        </label>
        { this.state.showState ?
          <label className="state">
            <span>State</span>
            <div className={'field'}>
              <select
                id={name + 'state-select'}
                name={name + 'state'}
                onChange={o => {
                  document.getElementById(name + 'state-select').style.color = '#31325f'
                }}
                style={defaultValue.state ? {} : {color: '#aab7c4'}} required defaultValue={defaultValue.state ? defaultValue.state : ''}>
                <option style={{display: 'none'}} value={''} disabled>CA</option>
                <option value={'AL'}>AL</option>
                <option value={'AK'}>AK</option>
                <option value={'AZ'}>AZ</option>
                <option value={'AR'}>AR</option>
                <option value={'CA'}>CA</option>
                <option value={'CO'}>CO</option>
                <option value={'CT'}>CT</option>
                <option value={'DE'}>DE</option>
                <option value={'FL'}>FL</option>
                <option value={'GA'}>GA</option>
                <option value={'HI'}>HI</option>
                <option value={'ID'}>ID</option>
                <option value={'IL'}>IL</option>
                <option value={'IN'}>IN</option>
                <option value={'IA'}>IA</option>
                <option value={'KS'}>KS</option>
                <option value={'KY'}>KY</option>
                <option value={'LA'}>LA</option>
                <option value={'ME'}>ME</option>
                <option value={'MD'}>MD</option>
                <option value={'MA'}>MA</option>
                <option value={'MI'}>MI</option>
                <option value={'MN'}>MN</option>
                <option value={'MS'}>MS</option>
                <option value={'MO'}>MO</option>
                <option value={'MT'}>MT</option>
                <option value={'NE'}>NE</option>
                <option value={'NV'}>NV</option>
                <option value={'NH'}>NH</option>
                <option value={'NJ'}>NJ</option>
                <option value={'NM'}>NM</option>
                <option value={'NY'}>NY</option>
                <option value={'NC'}>NC</option>
                <option value={'ND'}>ND</option>
                <option value={'OH'}>OH</option>
                <option value={'OK'}>OK</option>
                <option value={'OR'}>OR</option>
                <option value={'PA'}>PA</option>
                <option value={'RI'}>RI</option>
                <option value={'SC'}>SC</option>
                <option value={'SD'}>SD</option>
                <option value={'TN'}>TN</option>
                <option value={'TX'}>TX</option>
                <option value={'UT'}>UT</option>
                <option value={'VT'}>VT</option>
                <option value={'VA'}>VA</option>
                <option value={'WA'}>WA</option>
                <option value={'WV'}>WV</option>
                <option value={'WI'}>WI</option>
                <option value={'WY'}>WY</option>
              </select>
            </div>
          </label>
          : null
        }
        <label className={this.state.showState ? 'zip' : ''}>
          <span>{this.state.showState ? 'ZIP' : 'Postal Code'}</span>
          <input defaultValue={defaultValue.zip ? defaultValue.zip : ''}
                 name={name + "zip"} className="field" placeholder="94107" required/>
        </label>
        {hideCountry ?
          null :
          <CountrySelect
            name={name + 'country'}
            defaultValue={defaultValue.country ? defaultValue.country : 'US'}
            onChange={o => {
              if (o.target.value !== 'US' && this.state.showState) {
                this.setState({
                  showState: false,
                })
              } else if (o.target.value === 'US' && !this.state.showState) {
                this.setState({
                  showState: true,
                })
              }
            }}
          />
        }
      </React.Fragment>
    )
  }
}

AddressBlock.defaultProps = {
  defaultValue: {},
  name: ''
}

export default AddressBlock