import React, { Component } from 'react'

class CountrySelect extends Component {
  render() {
    const { defaultValue, onChange, name } = this.props
    return (
      <label className={'select'}>
        <span>Country</span>
        <div id="country" className={`field ${defaultValue.toLowerCase()}`}>
          <select
            onChange={o => {
              const selector = document.getElementById('country')
              selector.className = `field ${o.target.value.toLocaleLowerCase()}`
              onChange(o)
            }}
            defaultValue={defaultValue}
            name={name}>
            <option value="AU">Australia</option>
            <option value="AT">Austria</option>
            <option value="BE">Belgium</option>
            <option value="BR">Brazil</option>
            <option value="CA">Canada</option>
            <option value="CN">China</option>
            <option value="DK">Denmark</option>
            <option value="FI">Finland</option>
            <option value="FR">France</option>
            <option value="DE">Germany</option>
            <option value="HK">Hong Kong</option>
            <option value="IE">Ireland</option>
            <option value="IT">Italy</option>
            <option value="JP">Japan</option>
            <option value="LU">Luxembourg</option>
            <option value="MY">Malaysia</option>
            <option value="MX">Mexico</option>
            <option value="NL">Netherlands</option>
            <option value="NZ">New Zealand</option>
            <option value="NO">Norway</option>
            <option value="PT">Portugal</option>
            <option value="SG">Singapore</option>
            <option value="ES">Spain</option>
            <option value="SE">Sweden</option>
            <option value="CH">Switzerland</option>
            <option value="GB">United Kingdom</option>
            <option value="US">United States</option>
          </select>
        </div>
      </label>
    )
  }
}

export default CountrySelect