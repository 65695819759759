import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Home, Member, Donate } from './views'

class DefaultLayout extends Component {
  constructor(props) {
    super(props)

    this.checkout = React.createRef()
  }

  componentDidMount() {
    setTimeout(() => {
      this.checkout.current.style.visibility = 'visible'
      this.checkout.current.style.opacity = 1;
    }, 0)
  }

  render() {
    return (
      <div ref={this.checkout} id={'checkout'} style={{marginTop: 30}}>
        <Router>
          <Switch>
            <Route exact path={'/'} component={Home}/>
            <Route exact path={'/dues'} component={Member}/>
            <Route exact path={'/donate'} component={Donate}/>
          </Switch>
        </Router>
        <div style={{margin: 15}}><a href={'https://bluecoatsinc.org'}>return to Bluecoats, Inc.</a></div>
      </div>
    )
  }
}

export default DefaultLayout