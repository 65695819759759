import React, { Component } from 'react'
import { CardElement, PaymentRequestButtonElement, injectStripe } from 'react-stripe-elements'
import AddressBlock from "./AddressBlock"
import config from '../config'
import { ClipLoader } from 'react-spinners'

class PaymentDetails extends Component {
  constructor(props) {
    super(props)

    this.paymentRequest = React.createRef()

    const paymentRequest = props.stripe.paymentRequest({
      country: props.details.country ? props.details.country : 'US',
      currency: 'usd',
      total: {
        label: 'Total',
        amount: props.details.amount ? Math.ceil(props.details.amount * 100) : 0,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    this.state = {
      sameAsContact: false,
      sameAsBusiness: false,
      canMakePayment: false,
      paymentRequest,
      processing: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    const { onSubmit, details } = this.props

    this.state.paymentRequest.on('token', ({complete, token, ...data}) => {
      const cardholder = {
        name: data.payerName,
        email: data.payerEmail,
        phone: data.payerPhone,
      }

      if (!token) {
        return
      }

      onSubmit({
        token,
        cardholder,
        details
      })
    })

    this.state.paymentRequest.canMakePayment().then((result) => {
      this.setState({canMakePayment: !!result})
      if (!!result) {
        this.paymentRequest.current.style.visibility = 'visible'
        this.paymentRequest.current.style.opacity = 1
      }
    })
  }

  async handleSubmit(ev) {
    const { onSubmit, stripe, details, tokenName } = this.props
    const { target } = ev

    this.setState({
      processing: true
    })

    ev.preventDefault()

    let cardholder = {}
    if (this.state.sameAsContact) {
      cardholder = {
        name: details.name,
        address_line1: details.address,
        address_zip: details.zip,
        address_city: details.city,
        address_state: details.state,
        address_country: details.country,
      }
    } else if (this.state.sameAsBusiness) {
      cardholder = {
        name: details.name,
        address_line1: details.companyAddress.address,
        address_zip: details.companyAddress.zip,
        address_city: details.companyAddress.city,
        address_state: details.companyAddress.state,
        address_country: details.companyAddress.country,
      }
    } else {
      cardholder = {
        name: target.name.value,
        address_line1: target.address.value,
        address_zip: target.zip.value,
        address_city: target.city.value,
        address_state: target.state.value,
        address_country: target.country.value,
      }
    }

    const { token } = await stripe.createToken({name: tokenName})

    if (config.testToken === null && !token) {
      return
    }

    await onSubmit({
      token: config.testToken === null ? token : { id: config.testToken },
      cardholder,
      details
    })

    this.setState({
      processing: false
    })
  }

  render() {
    const { onBack, details, notices, error, paymentRequestType } = this.props

    return (
      <div>
        {!this.state.processing ?
          <div ref={this.paymentRequest} id={'payment-request'}>
            {this.state.canMakePayment ?
            <PaymentRequestButtonElement
              className="PaymentRequestButton"
              paymentRequest={this.state.paymentRequest}
              style={{
                paymentRequestButton: {
                  theme: 'dark',
                  height: '36px',
                  type: paymentRequestType,
                },
              }}
            />
              : null }
          </div>
        : null }
        <form id={'payment-form'} onSubmit={this.handleSubmit}>
          <div style={{display: this.state.processing ? 'none' : 'block' }} ref={this.content}>
            <p className="instruction"><span>{this.state.canMakePayment ? 'Or enter ' : 'Enter '} your billing and payment details below</span></p>
            <section>
              <h2>Billing Information</h2>
              <label style={{lineHeight: .8, fontSize: '14px'}}>
                Same as contact information
                <input
                  onChange={() => this.setState({
                    sameAsContact: !this.state.sameAsContact,
                    sameAsBusiness: false,
                  })}
                  checked={this.state.sameAsContact}
                  type={'checkbox'} style={{marginBottom: 15, float: 'left', marginRight: 10}}/>
              </label>
              { 'companyAddress' in details ?
                <label style={{lineHeight: .8, fontSize: '14px'}}>
                  Same as business information
                  <input
                    onChange={() => this.setState({
                      sameAsBusiness: !this.state.sameAsBusiness,
                      sameAsContact: false,
                    })}
                    checked={this.state.sameAsBusiness}
                    type={'checkbox'} style={{marginBottom: 15, float: 'left', marginRight: 10}}/>
                </label>
                : null
              }
              { !this.state.sameAsContact && !this.state.sameAsBusiness ?
                <fieldset className="with-state">
                  <label>
                    <span>Name</span>
                    <input name="name" className="field" placeholder="Jenny Rosen" required/>
                  </label>
                  <label>
                    <span>Email</span>
                    <input name="email" type="email" className="field" placeholder="jenny@example.com" required/>
                  </label>
                  <AddressBlock/>
                </fieldset>
                : null }
            </section>
            <div className="payment-info card visible">
              <section>
              <h2>Payment Information</h2>
              <fieldset>
                <label>
                  <span>Card</span>
                  <CardElement hidePostalCode={true} className={'field'}/>
                </label>
              </fieldset>
              </section>
              {error !== '' ?
                <label style={{display: 'block', fontSize: 14, color: 'red', marginBottom: 25}}>{error}</label>
                : null
              }
              {notices.map((n, key) => {
                return <p key={key} className={'tip'}>{n}</p>
              })}
            </div>
            <button className="payment-button" type="submit">Pay ${details.amount}</button>
            <button className="back-button" onClick={() => onBack()}>Back</button>
          </div>
          <ClipLoader loading={this.state.processing}/>
        </form>
      </div>
    )
  }
}

PaymentDetails.defaultProps = {
  notices: [],
  paymentRequestType: 'default'
}

export default injectStripe(PaymentDetails)