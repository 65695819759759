import React, { Component } from 'react'
import { StripeProvider } from 'react-stripe-elements'
import DefaultLayout from "./DefaultLayout"
import './App.css'
import * as request from 'request-promise-native'
import config from './config'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }

    config.baseUrl = request(`${window.location.origin}/config.json`, { json: true }, (err, res) => {
      if (!err) {
        config.publicKey = res.body.publicKey
        config.baseUrl = res.body.baseUrl
        config.testToken = 'testToken' in res.body ? res.body.testToken : null
        config.fiscalYearStart = res.body.fiscalYearStart
        this.setState({ loading: false })
      }
    })
  }

  render() {
    return this.state.loading ?
      null :
      <StripeProvider apiKey={config.publicKey}>
        <DefaultLayout/>
      </StripeProvider>
  }
}

export default App
